import React from "react";

import Layout from "../components/layout";
import ContactForm from "../components/ContactForm";
import SEO from "../components/seo";

const ContactPage = () => (
    <Layout>
        <SEO title="Contact Us" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-half">
                            <h1 className="title has-text-warning">Interested in hearing more?</h1>
                            <h2 className="subtitle">Send us a message and we will be in touch!</h2>
                        </div>
                        <div className="column">
                            <div className="container has-text-centered">
                                <ContactForm></ContactForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default ContactPage;
